/* DND Styles */

.column {
    width: 30%;
    /* background-color: rgb(252, 248, 248); */
    border-radius: 4px;
    padding: 5px;

}

.droppable-col {
    width: 100%;
    padding: 10px 10px 0 10px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
}

.item {
    margin-bottom: 10px;
    background-color: white;
    color: black;
    border: 1px solid white;
    padding: 5px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.item.dragging {
    background-color: coral;
}

/* ---------- Active Nav Tabs -------------- */

.nav-tabs .nav-item .nav-link.active:before,
.nav-tabs .nav-item .nav-link.active:hover:before,
.nav-tabs .nav-item .nav-link.active:focus:before {
    display: none !important;
}

.nav-link {
    transition: all ease-out 0.3s;
    cursor: pointer;

}

.nav-link.active {
    border-bottom: 2px solid #51bcda !important;
    transition: all ease-in 0.3s;
    cursor: pointer;
}


.nav-tabs .nav-item .nav-link.active:after,
.nav-tabs .nav-item .nav-link.active:hover:after,
.nav-tabs .nav-item .nav-link.active:focus:after {
    display: none !important;
}