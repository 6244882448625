:root {
  --testingBorder: 0px;
  --mainColor: #51bcda;
}

.reactstrap-spinner-custom-styling {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  height: 65px;
}

.title {
  margin: 0px;
}

.center-items-col {
  display: flex;
  align-items: center;
}

.custom-input {
  height: 35px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.custom-form-group {
  margin-bottom: 0px;
}

.pagination-select {
  min-width: 80px;
  margin-right: 5px;
  margin-left: 5px;
}

.pagination-button {
  margin-right: 5px;
  margin-left: 5px;
}

.title-with-add-btn {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-top: 10px;
  border: var(--testingBorder) solid red;
  align-items: center;
}

.top-add-btn {
  margin-left: 10px;
  font-size: 20px;
  color: var(--mainColor);
  cursor: pointer;
}

.avatar-image-small {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 10px;
}

.left-to-right-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.clickable-card {
  cursor: pointer;
}

.light-border-bottom {
  border-bottom: 1px solid #ddd;
}

.table-top-right-buttons {
  display: flex;
  flex-direction: row;
}

.flex-row {
  display: flex;
  flex-direction: row;
  border: var(--testingBorder) solid red;
  width: "100%";
}

.flex-row-left {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  border: var(--testingBorder) solid red;
}

.flex-row-right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  border: var(--testingBorder) solid red;
}

.avatar-image-medium {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 10px;
}

.single-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.btn-success-border {
  border: 1px solid #46a04866;
}

.btn-danger-border {
  border: 1px solid #f2423466;
}

.btn-info-border {
  border: 1px solid #4cb7d966;
}

.audio-verion-action-btn:hover {
  background-color: #ffffff !important;
  color: #403d39 !important;
}

.audio-verion-action-btn:active {
  background-color: #ffffff !important;
  color: #403d39 !important;
}

.audio-verion-action-btn:focus {
  background-color: #ffffff !important;
  color: #403d39 !important;
}

.btn-success-border:hover {
  border: 1px solid #46a048;
}

.btn-danger-border:hover {
  border: 1px solid #f24234;
}

.btn-info-border:hover {
  border: 1px solid #4cb7d9;
}

.version-left-tab-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: var(--testingBorder) solid red;
  cursor: pointer;
}

.version-left-tab-section .left-flex {
  display: flex;
  flex: 1;
  border: var(--testingBorder) solid red;
  font-size: 14px;
  align-items: center;
  padding-left: 5px;
}

.version-left-tab-section .right-flex {
  display: flex;
  flex: 1;
  border: var(--testingBorder) solid red;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  font-size: 20px;
}

.version-req-left-tab {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: var(--testingBorder) solid red;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.version-req-left-tab:hover {
  background-color: #4cb7d933;
}

.version-req-left-tab.active {
  background-color: #4cb7d9aa;
  color: #ffffff;
  font-weight: bold;
}

.version-req-left-tab .left-flex {
  display: flex;
  flex: 1;
  border: var(--testingBorder) solid red;
  font-size: 14px;
  align-items: center;
  padding-left: 5px;
}

.version-req-left-tab .right-flex {
  display: flex;
  flex: 1;
  border: var(--testingBorder) solid red;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
}

.compliant-req-icon {
  color: #46a048;
  font-size: 20px;
}

.non-compliant-req-icon {
  color: #f24234;
  font-size: 20px;
}
.partial-req-icon{
  color: #bed322;
  font-size: 20px;
}

.horizontal-tabs {
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  color: black;
  list-style-type: none;
}

.vontribute-lead-section-left-col {
  padding-right: 0px;
  border-right: 1px solid silver;
  /* margin-bottom: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.vontribute-lead-section-right-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.evidence-row {
  margin-top: 10px;
}

.life-cycle-req-section {
  background-color: white;
  height: 25px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}

.life-cycle-req-section span {
  margin-left: 26px;
  display: flex;
}

.life-cycle-req-section input {
  margin: 0px;
  margin-left: 7px;
}

.life-cycle-req-section .section-span {
  font-size: 12px;
  font-weight: bold;
}

.parent-crumb {
  cursor: pointer;
}

.parent-crumb:hover {
  text-decoration: underline;
}

.versions-switch-flex {
  display: flex;
  border: var(--testingBorder) solid red;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  justify-content: flex-end;
}

.versions-switch-flex .switch {
  margin-left: 10px;
  border: 0px solid red;
  align-items: center;
  display: flex;
  min-height: 50px;
}

.versions-switch-flex .select {
  width: 300px;
  border: 0px solid red;
  align-items: center;
  display: flex;
  min-height: 100px;
}

.versions-switch-flex .react-select {
  width: 300px;
}

.audit-version-list .version-heading {
  font-weight: bold;
  font-size: 16px;
}

.audit-version-list {
  width: 300px;
  margin-left: 20px;
  border: 0px solid red;
  float: left;
}

.audit-version-lists-parent {
  width: max-content;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.audit-version-lists-container {
  /* height: calc(100vh - 240px); */
  width: "90%";
  overflow-x: scroll;
  background-color: #e6ebed;
}


/* css for report generate */
.asessment-method-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* height: 100vh; */
  /* margin-top: 20rem; */

}



.ref-section-name-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* height: 100vh; */
  /* margin-top: 10rem; */
}

.subrequirment-method-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* height: 100vh; */
  /* margin-top: 10rem; */
}

/* 
.ref-section-name-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 0rem;
  
} */

/* canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

/* #chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
} */

/* #chartjs-radar {
  width: 60%;
  height: 60%;
} */

/* .chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
} */

.front-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2px;

}


