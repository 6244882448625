/* Datatable */
.table-card {
  display: block;
  max-width: 100%;
}

.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tableWrap table {
  width: 100%;
  border-spacing: 0;
}

.tableWrap table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-color: gray;
}

.tableWrap table tr:last-child {
  border-bottom: 0;
}

.tableWrap table tr th,
.tableWrap table tr td {
  padding: 12px 7px;
  vertical-align: middle;
}

.tableWrap table th td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  /* The secret sauce */
  /* Each cell should grow equally */
  width: 1%;
  position: relative;
}

.tableWrap table th td.collpased {
  width: 0.0000000001%;
}

.resizer {
  display: inline-block;
  background: blue;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.isResizing {
  background: red;
}

/*  ----------- Forms ---------- */

.card label {
  font-size: 0.8571em;
  margin-bottom: 5px;
  color: black !important;
  font-weight: bold !important;
}

/*  ----------- Bread crumbs ---------- */

.breadcrumbs {
  border: 0px solid red;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.single-crumb {
  align-items: center;
  display: flex;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.single-crumb:hover {
  color: #51cbce;
}

.crumb-separator {
  display: flex;
  align-items: center;
  font-size: 20px;
}

/* custom header  */
.table-info {
  background-color: #51bcda99;
}


/* user profile */

.card-user .card-body {
  min-height: 0px !important;
}